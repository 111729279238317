import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { ROOT_URL } from '../constants'
import { logger } from '../helpers/logger'
import { history } from '../navigation/history'

const api = axios.create({
  baseURL: ROOT_URL
})

export const logout = () => {
  localStorage.clear()

  if (window.location.pathname !== '/') {
    Sentry.configureScope(scope => scope.setUser(null))
    history.push('/')
  }
}

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      logout()
    }
    return Promise.reject(error)
  }
)

export const login = async ({ email, password }) => {
  try {
    const { data } = await api.post('/v1/provi-sign-auth/login', { email, password })
    return data
  } catch (error) {
    logger({ error })
    return error.response
  }
}

export const updatePassword = async ({ email, password, confirmPassword }) => {
  try {
    const { data } = await api.post('/v1/provi-sign-auth/change-password', { email, password, confirmPassword })
    return data
  } catch (error) {
    logger({ error })
    return error.response
  }
}

export const sign = async ({ ContractRecipientId, ProviContractId, device, WitnessId }) => {
  try {
    const { data } = await api.post('/v1/sign-dashboard/sign', { ContractRecipientId, ProviContractId, device, WitnessId })
    return data
  } catch (error) {
    logger({ error })
    return error.response
  }
}

export const signLote = async ({ ProviContractsIds, device, url }) => {
  try {
    const response = await api.post('/v1/sign-dashboard/get-auth', { url })

    const { data } = await api.post('/v1/sign-dashboard/sign-list', {
      ProviContractsIds,
      device,
      WitnessId: response.data.WitnessId
    })
    return data
  } catch (error) {
    logger({ error })
    return error.response
  }
}

export const getContracts = async ({ from, to, signed, offset, limit, url, PartnerId }) => {
  try {
    await api.post('/v1/sign-dashboard/get-auth', { url })

    const { data } = await api.get('/v1/sign-dashboard/get-contracts', {
      params: {
        ...(to && from ? { from } : {}),
        ...(to && from ? { to } : {}),
        ...(signed ? { signed } : {}),
        offset,
        limit,
        PartnerId
      }
    })
    return data
  } catch (error) {
    logger({ error })
    return error.response
  }
}

export const getRecipients = async ({ ProviContractId, url }) => {
  try {
    const response = await api.post('/v1/sign-dashboard/get-auth', { url })

    const { data } = await api.get(`/v1/sign-dashboard/get-recipients/${ProviContractId}`)

    return { dataRecipients: data, CPF: response.data.CPF, WitnessId: response.data.WitnessId, CNPJ: response.data.CNPJ }
  } catch (error) {
    logger({ error })
    return error.response
  }
}

export const getPartners = async ({ url }) => {
  try {
    const response = await api.post('/v1/sign-dashboard/get-auth', { url })
    return api.get(`/v1/sign-dashboard/partners-witness/${response.data.WitnessId}`)
  } catch (error) {
    logger({ error })
    return error.response
  }
}
