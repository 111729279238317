import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { setupFirebase } from './services/firebase'

const { REACT_APP_FIREBASE_ENV } = process.env

// sentry active only production.
if (REACT_APP_FIREBASE_ENV === 'production') {
  Sentry.init({ dsn: 'https://b4e5c165bc454585916810de0b5a0ab4@o245612.ingest.sentry.io/5551450' })
}

// initial setup firebase
setupFirebase(REACT_APP_FIREBASE_ENV)

moment.updateLocale('pt-br', require('moment/locale/pt-br'))
