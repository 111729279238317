import React from 'react'
import { Section, ImageWrapper, Img, Title, Text, TextWrapper } from './styles'
import mobileImg from '../../assets/images/mobile.png'

export const MobileScreen = () => {
  return (
    <Section>
      <ImageWrapper>
        <Img alt="" src={mobileImg} />
      </ImageWrapper>
      <TextWrapper>
        <Title>Sua resolução é muito baixa para essa versão da plataforma Provi.</Title>
        <Text>
          A plataforma Provi foi pensada para ser usada no seu desktop! Infelizmente, ainda não temos uma versão mobile.
        </Text>
      </TextWrapper>
    </Section>
  )
}
