import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;

  background-color: ${theme.colors.blue900};
  padding: 0px 40px;
  z-index: 10;
`
export const ControlBox = styled.div`
  display: flex;
  padding: 40px;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
`
export const Wrapper = styled.div``

export const Options = styled.div`
  display: flex;
  align-items: center;
`
