import React from 'react'
import { Screen, BoundaryBox, Developer, Error } from './style'

import errorText from '../../../assets/images/503.png'
import developer from '../../../assets/images/developer.png'

export const Background = () => {
  return (
    <Screen>
      <BoundaryBox>
        <Error src={errorText} />
      </BoundaryBox>
      <BoundaryBox>
        <Developer src={developer} />
      </BoundaryBox>
    </Screen>
  )
}
