import React from 'react'
import { theme, Button, TextLink } from '@provi/provi-components'

import { Modal as ModalComponent } from '../Modal'

import { Section, Row, Text } from './styles'
import { useSignLoteModal } from './hooks'

export const SignLoteModal = ({ show, signContractLote, setShowModal }) => {
  const [signLote] = useSignLoteModal(signContractLote, setShowModal)

  return (
    <>
      {show && (
        <ModalComponent
          isWithoutHeight
          width="630px"
          radius={5}
          onDismiss={() => setShowModal(false)}
          footer={
            <Row>
              <Button marginVertical={0} text="Confirmar assinatura" onClick={signLote} />
              <TextLink color={theme.colors.blue900} text="Cancelar" onClick={() => setShowModal(false)} />
            </Row>
          }
        >
          <Section>
            <Text size="20px" lineHeight="30px" bold align="left" color={theme.colors.blue900}>
              Confirma a assinatura de todos os contratos pendentes?
            </Text>
            <Text mTop="25px" size="16px" lineHeight="24px" align="left" color={theme.colors.blue900}>
              Esse processo pode levar até 5 minutos!
            </Text>
          </Section>
        </ModalComponent>
      )}
    </>
  )
}
