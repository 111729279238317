import React from 'react'

/**
 * @typedef {object} ITemplateData
 * @property {boolean} isMobile
 * @property {boolean} isLoading
 * @property {boolean} setIsLoading
 */

/** @type {React.Context<ITemplateData>} */
export const TemplateContext = React.createContext({})

export const TemplateConsumer = TemplateContext.Consumer
