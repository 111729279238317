import styled from 'styled-components'

/** @type {import('styled-components').StyledComponent<"div", any, {
 *  borderColor: string,
 *  backgroundColor: string,
 *  color: string,
 *  textTransform: string,
 *  fontSize: number,
 *  lineHeight: number,
 *  paddingLeft: number,
 *  paddingRight: number,
 *  maxWidth: number
 * }>} */
export const Container = styled.button`
  border-color: ${props => props.borderColor};
  background-color: ${props => props.backgroundColor};
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.lineHeight}px;
  color: ${props => props.color};
  text-transform: ${props => props.textTransform};
  padding-left: ${props => props.paddingLeft}px;
  padding-right: ${props => props.paddingRight}px;
  ${props =>
    props.maxWidth
      ? `
    max-width: ${props.maxWidth}px;
    width: 100%;
  `
      : ''};

  margin: 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-family: 'CenturyGothicBold';
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  cursor: ${props => props.cursor};

  :focus {
    outline: none;
    box-shadow: none;
  }
`
