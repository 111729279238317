import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Section = styled.div`
  width: 100vw;
  min-height: 100vh;
  border: 1px solid red;
  background-color: #e5e5e5;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: 'CenturyGothic';
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    padding-top: 30px;
  }
`

export const Img = styled.img`
  ${theme.breakpoints.down('sm')} {
    width: 80%;
    height: auto;
  }
`

export const TextWrapper = styled.div`
  max-width: 500px;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${theme.breakpoints.down('sm')} {
    padding: 30px;
  }
`

export const Title = styled.h2`
  color: ${theme.colors.blue900};
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
`

export const Text = styled.p`
  color: ${theme.colors.blue900};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`
