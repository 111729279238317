import * as Sentry from '@sentry/browser'
import { asyncLocalStorage } from '../utils'

export const logger = async props => {
  const partner = await asyncLocalStorage.getItem('partner-name')
  const email = await asyncLocalStorage.getItem('email')
  const token = await asyncLocalStorage.getItem('token')

  console.log('ERROR ->>>>>: ', props && props.error)
  console.log('INFO EXTRA ->>>>>: ', props && props.info && props.info.componentStack)
  console.log('EMAIL USER ->>>>>: ', email)

  if (props && props.error) {
    Sentry.withScope(scope => {
      scope.setTag('PARTNER', partner)
      scope.setUser({ email, token })
      props.info &&
        Object.keys(props.info).forEach(key => {
          scope.setExtra(key, props.info[key])
        })
      Sentry.captureException(props.error)
    })
  }
}
