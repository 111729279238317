import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Section = styled.div`
  margin-bottom: 35px;
  text-align: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Text = styled.div`
  ${({ color }) => (color ? `color: ${color};` : `color : ${theme.colors.blue900};`)}
  ${({ bold }) => (bold ? `font-family: CenturyGothicBold;` : `font-family: CenturyGothic;`)}

  ${({ size }) => (size ? `font-size: ${size};` : ``)}
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : ``)}
  ${({ align }) => (align ? `text-align: ${align};` : ``)}
  ${({ display }) => (display ? `display: ${display};` : `display: block;`)}


  /* margins */
  ${({ margin }) => (margin ? `margin: ${margin};` : ``)}

  ${({ mTop }) => (mTop ? `margin-top: ${mTop};` : ``)}
  ${({ mLeft }) => (mLeft ? `margin-left: ${mLeft};` : ``)}
  ${({ mRight }) => (mRight ? `margin-right: ${mRight};` : ``)}
  ${({ mBottom }) => (mBottom ? `margin-bottom: ${mBottom};` : ``)}

  /* more props */
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : ``)}
  ${({ textOverflow }) => (textOverflow ? `text-overflow: ${textOverflow};` : ``)}
  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace};` : ``)}
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : ``)}
`
