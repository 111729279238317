import React, { useContext } from 'react'
import { TemplateContext } from '../../components/template/context'
import { Template } from '../../components/template'
import { FormFormik } from './form'
import { useLogin } from './hooks'

export const Login = () => {
  const { setIsLoading } = useContext(TemplateContext)

  useLogin(setIsLoading)

  return (
    <Template>
      <FormFormik setIsLoading={setIsLoading} />
    </Template>
  )
}
