import React, { useState } from 'react'
import { useScreenMeasure } from './hooks'
import { TemplateContext } from './context'

export const TemplateProvider = props => {
  const [isMobile] = useScreenMeasure()
  const [isLoading, setIsLoading] = useState(false)
  const value = {
    isMobile,
    setIsLoading,
    isLoading
  }

  return <TemplateContext.Provider value={value}>{props.children}</TemplateContext.Provider>
}
