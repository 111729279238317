import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { theme } from '@provi/provi-components'
import { getContracts, sign, signLote, getRecipients, getPartners } from '../../services/api'
import { Download } from '../../assets/svgs/download'
import { DownloadWrapper } from './styles'

import { Button } from '../../components/Button'
import { StatusBox } from '../../components/StatusBox'
import { showToast } from '../../components/Toast'

const getChanges = (current, previous) => {
  let changes = {}
  for (const key in current) {
    if (current[key] !== previous[key]) {
      changes = {
        ...changes,
        [key]: {
          from: previous[key],
          to: current[key]
        }
      }
    }
  }
  return changes
}

export const useContracts = setIsLoading => {
  const INITIAL_STATE = [
    { item: 'status', label: 'Status' },
    { item: 'userName', label: 'Nome do usuário' },
    { item: 'partnerName', label: 'Nome do parceiro' },
    { item: 'emissionDate', label: 'Data de emissão', showSelect: false, isDate: true, startDate: new Date() },
    { item: 'contractValue', label: 'Valor do contrato' },
    { item: 'actions', label: '', active: true }
  ]
  const [headerRow, setHeaderRow] = useState(INITIAL_STATE)
  const [showModal, setShowModal] = useState(false)
  const [rows, setRows] = useState([])
  const [options, setOptions] = useState([])
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [filters, setFilters] = useState({
    from: null,
    to: null,
    signed: false,
    offset: 0,
    limit: 50
  })
  const [oldFilters, setOldFilters] = useState({
    from: null,
    to: null,
    signed: false,
    offset: 0,
    limit: 50
  })

  const signContract = async ProviContractId => {
    setIsLoading(true)
    const urlUnstructured = window.location.href.split('/')

    try {
      const device = navigator.userAgent || null

      const { dataRecipients, CPF, WitnessId } = await getRecipients({
        ProviContractId,
        url: urlUnstructured[urlUnstructured.length - 1]
      })
      const recipients = dataRecipients.recipients

      const { ContractRecipientId } = recipients.find(recipient => recipient.CPF === `${CPF}`)

      const data = await sign({ ContractRecipientId, ProviContractId, device, WitnessId })
      if (!data.error) {
        showToast('Assinatura concluída com sucesso!')
      }
      await getDataContracts({ update: false })
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const signContractLote = async () => {
    setIsLoading(true)
    const urlUnstructured = window.location.href.split('/')
    try {
      const ProviContractsIds = rows.filter(contract => !contract.signed).map(contract => contract.ProviContractId)

      if (ProviContractsIds.length) {
        const device = navigator.userAgent || null

        await signLote({ ProviContractsIds, device, url: urlUnstructured[urlUnstructured.length - 1] })
        await getDataContracts({ update: false })
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const downloadContract = contractUrl => {
    window.open(contractUrl, '_blank')
  }

  const getDataContracts = async ({ update = false, PartnerId = null }) => {
    setIsLoading(true)
    const urlUnstructured = window.location.href.split('/')

    try {
      const { contracts } = await getContracts({
        ...filters,
        url: urlUnstructured[urlUnstructured.length - 1],
        PartnerId
      })
      const builtContractRows = contracts.map(contract => ({
        ...contract,
        status: <StatusBox status={contract.signed ? 'signed' : 'pending'} />,
        emissionDate: moment(contract.emissionDate).format('DD/MM/YYYY'),
        contractValue: `R$ ${contract.contractValue}`,
        CCBNumbers: contract.CCBNumbers || '--',
        actions: (
          <>
            {!contract.signed && (
              <Button
                backgroundColor="transparent"
                borderColor={theme.colors.pink900}
                color={theme.colors.pink900}
                onClick={() => signContract(contract.ProviContractId)}
              >
                Assinar
              </Button>
            )}
            <Button
              backgroundColor="transparent"
              borderColor={theme.colors.pink900}
              color={theme.colors.pink900}
              paddingLeft={10}
              paddingRight={10}
              onClick={() => downloadContract(contract.contractUrl)}
            >
              <DownloadWrapper>
                <Download />
              </DownloadWrapper>
            </Button>
          </>
        )
      }))
      if (update) {
        const offsetWithLimit = filters.offset + filters.limit
        const newRows = [...rows, ...builtContractRows]
        setCanLoadMore(offsetWithLimit <= newRows.length)
        setRows([...rows, ...builtContractRows])
      } else {
        const offsetWithLimit = filters.offset + filters.limit
        const newRows = builtContractRows.length ? [...builtContractRows] : []
        setCanLoadMore(offsetWithLimit <= newRows.length)
        setRows(newRows)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const filterSigneds = () => {
    setFilters({ ...filters, signed: true, offset: 0 })
    const actions = headerRow.find(cell => cell.item === 'actions')
    actions.active = false
    setHeaderRow([...headerRow])
  }

  const filterUnsigneds = () => {
    setFilters({ ...filters, signed: false, offset: 0 })
    const actions = headerRow.find(cell => cell.item === 'actions')
    actions.active = true
    setHeaderRow([...headerRow])
  }

  const toggleShowSelect = index => {
    headerRow[index].showSelect = !headerRow[index].showSelect
    setHeaderRow([...headerRow])
  }

  const updateFilterDate = ({ to, from }) => {
    setFilters({ ...filters, to, from, offset: 0 })
  }

  const loadMore = () => {
    setFilters({ ...filters, offset: filters.offset + 50 })
  }

  useEffect(() => {
    async function LoadDatas() {
      const urlUnstructured = window.location.href.split('/')

      const changes = getChanges(filters, oldFilters)

      const { data } = await getPartners({ url: urlUnstructured[urlUnstructured.length - 1] })
      if (data && data.data.Partners.length && data.data.type === 'Partner') {
        const partnersArr = data.data.Partners[0]
        const formatOptions = partnersArr.map(partner => {
          return { value: partner.PartnerId, label: partner.PartnerName }
        })

        setOptions(formatOptions)

        if (changes.offset && filters.offset > oldFilters.offset) {
          getDataContracts({ update: true, PartnerId: formatOptions[0].value })
          return setOldFilters({ ...filters })
        } else {
          getDataContracts({ update: false, PartnerId: formatOptions[0].value })
          return setOldFilters({ ...filters, offset: 0 })
        }
      }

      if (changes.offset && filters.offset > oldFilters.offset) {
        getDataContracts({ update: true })
        setOldFilters({ ...filters })
      } else {
        getDataContracts({ update: false })
        setOldFilters({ ...filters, offset: 0 })
      }
    }
    LoadDatas()
  }, [filters])

  return [
    headerRow,
    rows,
    filters,
    filterSigneds,
    filterUnsigneds,
    toggleShowSelect,
    updateFilterDate,
    signContractLote,
    loadMore,
    canLoadMore,
    showModal,
    setShowModal,
    options,
    getDataContracts
  ]
}
