import React, { useContext } from 'react'

import { theme } from '@provi/provi-components'

import { TemplateContext } from '../../components/template/context'
import { Button } from '../../components/Button'
import { Table } from '../../components/Table'
import { Header } from '../../components/Header'
import { SignLoteModal } from '../../components/SignLoteModal'

import { useContracts } from './hooks'
import { Content, ContainerTable } from './styles'
import { Selector } from '../../components/Select'

export const Witness = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const [
    headerRow,
    rows,
    filters,
    filterSigneds,
    filterUnsigneds,
    toggleShowSelect,
    updateFilterDate,
    signContractLote,
    loadMore,
    canLoadMore,
    showModal,
    setShowModal,
    options,
    getDataContracts
  ] = useContracts(setIsLoading)

  return (
    <>
      <Header>
        {options.length && (
          <Selector
            placeholder=""
            width={'225px'}
            name="screenSelector"
            defaultValue={options[0]}
            onChange={item => {
              getDataContracts({ update: false, PartnerId: item.value })
            }}
            options={options}
          />
        )}
        <Button
          backgroundColor={!filters.signed ? theme.colors.pink900 : theme.colors.white100}
          color={!filters.signed ? theme.colors.white100 : theme.colors.blue900}
          borderColor={!filters.signed ? theme.colors.pink900 : theme.colors.white100}
          textTransform="uppercase"
          onClick={filterUnsigneds}
        >
          Contratos pendentes
        </Button>
        <Button
          backgroundColor={filters.signed ? theme.colors.pink900 : theme.colors.white100}
          color={filters.signed ? theme.colors.white100 : theme.colors.blue900}
          borderColor={filters.signed ? theme.colors.pink900 : theme.colors.white100}
          textTransform="uppercase"
          onClick={filterSigneds}
        >
          Contratos assinados
        </Button>
      </Header>

      <Content>
        <ContainerTable>
          <Table
            rows={rows}
            headerRow={headerRow}
            toggleShowSelect={toggleShowSelect}
            updateFilterDate={updateFilterDate}
            setShowModal={setShowModal}
            loadMore={loadMore}
            canLoadMore={canLoadMore}
          />
        </ContainerTable>
      </Content>
      <SignLoteModal show={showModal} signContractLote={signContractLote} setShowModal={setShowModal} />
    </>
  )
}
