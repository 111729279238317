import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

export const setupFirebase = enviroment => {
  let config
  if (enviroment === 'production') {
    config = {
      apiKey: 'AIzaSyApeg81WRbeP8sqIJRaX00BqzbFSka-HPo',
      authDomain: 'fe-user-dashboard.firebaseapp.com',
      databaseURL: 'https://fe-user-dashboard.firebaseio.com',
      projectId: 'fe-user-dashboard',
      storageBucket: 'fe-user-dashboard.appspot.com',
      messagingSenderId: '782531872442',
      appId: '1:782531872442:web:eade4e94be3cbba30ba483',
      measurementId: 'G-S62EE6RX6C'
    }
  } else {
    config = {
      apiKey: 'AIzaSyDAbMWTEV0aNdTb5zRDbPRSQpr3DClaWZo',
      authDomain: 'fe-user-dashboard-staging.firebaseapp.com',
      databaseURL: 'https://fe-user-dashboard-staging.firebaseio.com',
      projectId: 'fe-user-dashboard-staging',
      storageBucket: 'fe-user-dashboard-staging.appspot.com',
      messagingSenderId: '626090589086',
      appId: '1:626090589086:web:d2c0425567529a484db48f',
      measurementId: 'G-EVTMD9KRBS'
    }
  }

  return firebase.initializeApp(config)
}

export default firebase
