import React from 'react'

import { Container } from './styles'
import { theme } from '@provi/provi-components'

/**
 * @typedef {object} IButton
 * @property {string=} cursor
 * @property {string=} borderColor
 * @property {string=} backgroundColor
 * @property {string=} color
 * @property {string=} textTransform
 * @property {number=} fontSize
 * @property {number=} lineHeight
 * @property {number=} paddingLeft
 * @property {number=} paddingRight
 * @property {number=} maxWidth
 */

/** @type {React.FC<IButton>} */
export const Button = ({
  children,
  borderColor = theme.colors.pink900,
  backgroundColor = theme.colors.pink900,
  color = theme.colors.white100,
  textTransform = 'initial',
  fontSize = 12,
  lineHeight = 30,
  paddingLeft = 18,
  paddingRight = 18,
  maxWidth = null,
  cursor = 'pointer',
  onClick,
  disabled
}) => (
  <>
    {!disabled && (
      <Container
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
        textTransform={textTransform}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        maxWidth={maxWidth}
        onClick={onClick}
        cursor={cursor}
        disabled={disabled}
      >
        {children}
      </Container>
    )}
  </>
)
