/* eslint-disable react/prop-types */
import React from 'react'
import Select from 'react-select'
import { theme } from '@provi/provi-components'
import { View } from './styles'

const customStyles = () => ({
  option: () => ({
    padding: 5,
    fontSize: 14,
    paddingLeft: 13,
    color: theme.colors.blue800,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.grey500
    },
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  control: provided => ({
    ...provided,
    border: `1px solid ${theme.colors.blue800}`,
    borderRadius: 100,
    height: 42,
    fontFamily: 'CenturyGothic, sans serif',
    cursor: 'pointer'
  }),
  singleValue: provided => ({
    ...provided,
    color: theme.colors.blue800,
    fontSize: 16,
    lineHeight: 34
  }),
  input: provided => ({
    ...provided,
    color: theme.colors.blue800,
    fontSize: 16,
    fontFamily: 'CenturyGothic, sans serif'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: theme.colors.pink900,
    marginRight: 8
  }),
  placeholder: () => ({
    fontFamily: 'CenturyGothic, sans serif',
    color: theme.colors.grey600,
    fontSize: 16
  }),
  indicatorSeparator: () => ({}),
  menuList: menuList => ({
    ...menuList,
    padding: 0,
    zIndex: 100
  }),
  menu: provided => ({
    ...provided,
    position: 'absolute',
    borderCollapse: 'separate',
    border: '1px solid #323B69',
    boxSizing: 'border-box',
    borderRadius: '20px',
    overflow: 'auto',
    fontFamily: 'CenturyGothic, sans serif'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '2px 16px',
    fontSize: 20
  })
})

export const Selector = ({ options, value, onValueChange, name, width = '100%', ...props }) => (
  <View width={width}>
    <Select
      inputProps={{ 'aria-label': { name } }}
      noOptionsMessage={() => 'Sem opçōes'}
      styles={customStyles()}
      options={options}
      value={value}
      onChange={onValueChange}
      {...props}
    />
  </View>
)
