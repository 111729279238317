import { NotFound } from '../screens/NotFound'
import { Login } from '../screens/Login'
import { ChangePassword } from '../screens/ChangePassword'
import { FinancialInstitution } from '../screens/FinancialInstitution'
import { Partners } from '../screens/Partners'
import { Witness } from '../screens/Witness'

export const routes = [
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/',
    component: Login,
    exact: true
  },
  {
    path: '/parceiro',
    private: true,
    component: Partners
  },
  {
    path: '/testemunha',
    private: true,
    component: Witness
  },
  {
    path: '/bmp',
    private: true,
    component: FinancialInstitution
  },
  {
    path: '/redefinir-senha',
    private: true,
    component: ChangePassword
  }
]
