import React, { useState } from 'react'

export const useTable = ({ updateFilterDate, toggleShowSelect }) => {
  const [from, setFrom] = useState()
  const [to, setTo] = useState()

  const clearDateFilter = index => {
    toggleShowSelect(index)
    updateFilterDate({ to: null, from: null })
    setTo(null)
    setFrom(null)
  }

  const submitDateFilter = index => {
    updateFilterDate({ to, from })
    toggleShowSelect(index)
  }

  return [from, setFrom, to, setTo, submitDateFilter, clearDateFilter]
}
