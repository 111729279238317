import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { View } from './style'
import { MobileScreen } from '../Mobile'

/** @type {React.FC} */
export const Template = props => {
  const { isLoading, isMobile } = useContext(TemplateContext)

  return (
    <View>
      {isLoading && <LoadingProvi />}
      {isMobile ? <MobileScreen /> : props.children}
    </View>
  )
}
