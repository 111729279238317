import React from 'react'
import { theme, TextLink } from '@provi/provi-components'
import { makeStyles } from '@material-ui/core/styles'
import { Table as TableMaterial, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'
import { TableBodyAdapter, DateWrapper, DropItens, ContainerAction, ButtonFilter, ContainerLoadMore } from './styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { IconFilter } from '../../assets/svgs/icon-filter'
import { Button } from '../../components/Button'
import { useTable } from './hooks'

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 1000
  },
  container: {
    maxHeight: '100%',
    minHeight: '100%'
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    color: theme.colors.blue900,
    fontSize: '12px',
    lineHeight: '24px',
    fontFamily: 'CenturyGothicBold !important',
    backgroundColor: `${theme.colors.grey600} !important`,
    position: 'relative'
  },
  cell: {
    whiteSpace: 'nowrap',
    color: theme.colors.blue900,
    fontSize: '16px',
    lineHeight: '30px',
    fontFamily: 'CenturyGothic !important',
    padding: '16px !important'
  },
  tableBody: {
    position: 'relative'
  }
})

export const Table = ({ headerRow, rows, toggleShowSelect, updateFilterDate, setShowModal, loadMore, canLoadMore }) => {
  const classes = useStyles()
  const [from, setFrom, to, setTo, submitDateFilter, clearDateFilter] = useTable({ updateFilterDate, toggleShowSelect })

  return (
    <TableContainer className={classes.container} component={Paper}>
      <TableMaterial stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headerRow &&
              headerRow.length &&
              headerRow.map((cell, index) => (
                <>
                  <TableCell key={index} className={classes.headerTitle} align="center">
                    {cell.item !== 'actions' ? (
                      <div
                        onClick={() => {
                          cell.showSelect === false && toggleShowSelect(index)
                        }}
                      >
                        {cell.label} {cell.isDate && <IconFilter />}
                      </div>
                    ) : (
                      <Button
                        backgroundColor={cell.active ? theme.colors.pink900 : theme.colors.grey500}
                        borderColor={cell.active ? theme.colors.pink900 : theme.colors.grey500}
                        color={cell.active ? theme.colors.white100 : '#000'}
                        onClick={() => setShowModal(true)}
                        cursor={cell.active ? 'pointer' : 'initial'}
                        disabled={!cell.active || !rows.length}
                      >
                        Assinar todos
                      </Button>
                    )}

                    {cell.showSelect && (
                      <DropItens>
                        {cell.isDate && (
                          <>
                            <DateWrapper>
                              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  clearable
                                  value={(from && moment(from, 'YYYY-MM-DD').toDate()) || new Date()}
                                  placeholder="10/10/2018"
                                  onChange={event =>
                                    setFrom(
                                      moment(event)
                                        .format('YYYY-MM-DD')
                                        .toString()
                                    )
                                  }
                                  format="dd/MM/yyyy"
                                  id="date-picker-start"
                                  label="Data de início"
                                />
                              </MuiPickersUtilsProvider>
                            </DateWrapper>
                            <DateWrapper>
                              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  clearable
                                  value={(to && moment(to, 'YYYY-MM-DD').toDate()) || new Date()}
                                  placeholder="10/10/2018"
                                  onChange={event =>
                                    setTo(
                                      moment(event)
                                        .format('YYYY-MM-DD')
                                        .toString()
                                    )
                                  }
                                  format="dd/MM/yyyy"
                                  id="date-picker-end"
                                  label="Data de término"
                                />
                              </MuiPickersUtilsProvider>
                            </DateWrapper>
                            <ContainerAction>
                              <ButtonFilter onClick={() => submitDateFilter(index)}>Filtrar</ButtonFilter>
                              <TextLink text="Limpar filtros" onClick={() => clearDateFilter(index)} />
                            </ContainerAction>
                          </>
                        )}
                      </DropItens>
                    )}
                  </TableCell>
                </>
              ))}
          </TableRow>
        </TableHead>
        <TableBodyAdapter className={classes.tableBody}>
          {rows &&
            rows.length > 0 &&
            rows.map(row => (
              <TableRow key={row.id}>
                {headerRow &&
                  headerRow.map((cell, index) => (
                    <TableCell key={index} className={classes.cell} align="center">
                      {row[cell.item]}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={headerRow.length}>
              <ContainerLoadMore>
                <Button
                  backgroundColor={canLoadMore ? theme.colors.pink900 : theme.colors.grey500}
                  borderColor={canLoadMore ? theme.colors.pink900 : theme.colors.grey500}
                  color={canLoadMore ? theme.colors.white100 : '#000'}
                  onClick={() => canLoadMore && loadMore()}
                  cursor={canLoadMore ? 'pointer' : 'initial'}
                  textTransform="uppercase"
                >
                  carregar mais
                </Button>
              </ContainerLoadMore>
            </TableCell>
          </TableRow>
        </TableBodyAdapter>
      </TableMaterial>
    </TableContainer>
  )
}
