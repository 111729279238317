import React from 'react'
import { Redirect, Route, Switch, Router } from 'react-router-dom'
import { Toast } from '../components/Toast'
import { routes } from './routes'
import { history } from './history'
import { Template } from '../components/template'

export const Navigation = () => {
  return (
    <Template>
      <Router history={history}>
        <Switch>
          {routes.map(item => (
            <Route key={item.path} path={item.path} exact component={item.component} />
          ))}
          <Redirect to="/" exact />
        </Switch>
        <Toast />
      </Router>
    </Template>
  )
}
