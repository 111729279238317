import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import LoginVector from '../../assets/images/loginVector.png'

export const Section = styled.div`
  min-width: 100%;
  min-height: 100vh;

  background-image: url(${LoginVector});
  background-repeat: no-repeat;
  background-position: 100% 100%;

  background-color: ${theme.colors.blue900};
`

export const RestrictArea = styled.div`
  height: 50px;
  min-width: 100%;
  background-color: ${theme.colors.pink900};

  display: flex;
  align-items: center;
`

export const LoginLock = styled.img`
  padding-left: 100px;
`

export const RestrictAreaText = styled.p`
  font-family: 'CenturyGothic';
  font-weight: bold;

  padding-left: 15px;

  color: white;

  font-size: 16px;
  line-height: 24px;
`

export const InputArea = styled.div``

export const ContainerText = styled.div``

export const Title = styled.div`
  font-size: 48px;
  line-height: 59px;
  font-family: 'CenturyGothicBold';
  font-weight: bold;
  color: ${theme.colors.pink900};
`

export const SubTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.white100};
  margin: 20px 0;
`

export const Form = styled.form`
  margin-top: 34px;
  width: 100%;
  max-width: 400px;

  .MuiInput-underline:after {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInput-root {
      margin-bottom: 8px;
    }
    &:after {
      border-bottom: 2px solid #ffffff;
    }
  }
`

export const LoginAreaWrapper = styled.div`
  max-width: 750px;
  width: 100%;
  padding: 150px 0px 0px 100px;
`

export const Logo = styled.div`
  margin-bottom: 40px;
`
