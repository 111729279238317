import React from 'react'

export const Download = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 10.3333V13.4444C15 13.857 14.8361 14.2527 14.5444 14.5444C14.2527 14.8361 13.857 15 13.4444 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V10.3333M4.11127 6.4446L8.00016 10.3335M8.00016 10.3335L11.889 6.4446M8.00016 10.3335L8 1"
      stroke="#DE0C4B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
