import React from 'react'

import { LogoProvi } from '../../assets/svgs/logo-provi'
import { Container, Wrapper, Options, ControlBox } from './styles'

/** @type {React.FC<{}>} */
export const Header = ({ children }) => (
  <Container>
    <Wrapper>
      <LogoProvi />
    </Wrapper>
    <ControlBox>
      <Options>{children}</Options>
    </ControlBox>
  </Container>
)
