import React from 'react'
import { Wrapper, Screen, Wrapper404, WrapperErrorText, BoldText, Text, WrapperIcon } from './style'

import { Text404 } from '../../assets/svgs/404'
import { ErrorText404 } from '../../assets/svgs/404-error-text'
import { LogoProvi } from '../../assets/svgs/logo-provi'

export const NotFound = () => {
  return (
    <Wrapper>
      <Screen>
        <Wrapper404>
          <Text404 />
        </Wrapper404>
        <WrapperErrorText>
          <ErrorText404 />

          <BoldText>Desculpe!</BoldText>
          <Text>Alguma coisa deu errado por nossa culpa.</Text>
          <WrapperIcon>
            <LogoProvi isDark={true} />
          </WrapperIcon>
        </WrapperErrorText>
      </Screen>
    </Wrapper>
  )
}
