import React from 'react'

import { theme } from '@provi/provi-components'
import { Button } from '../Button'

/**
 * @typedef {object} IStatusBox
 * @property {string} status
 */

/** @type {React.FC<IStatusBox>} */
export const StatusBox = ({ status }) => {
  switch (status) {
    case 'signed':
      return (
        <Button
          backgroundColor={theme.colors.pink900}
          borderColor={theme.colors.pink900}
          textTransform="uppercase"
          maxWidth={190}
          cursor="initial"
        >
          Assinado
        </Button>
      )
    case 'pending':
      return (
        <Button
          backgroundColor={theme.colors.yellow900}
          borderColor={theme.colors.yellow900}
          textTransform="uppercase"
          maxWidth={190}
          cursor="initial"
        >
          Pendente de assinatura
        </Button>
      )
  }
}
