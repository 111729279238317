import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Lock } from '@material-ui/icons'
import { Input, Button, Label, theme } from '@provi/provi-components'
import { history } from '../../navigation/history'
import { showToast } from '../../components/Toast'
import { TextErrorInput } from '../../components/TextErrorInput'
import { LogoProvi } from '../../assets/svgs/logo-provi'
import { updatePassword } from '../../services/api'
import { asyncLocalStorage } from '../../utils'

import { ContainerText, Title, Form, LoginAreaWrapper, Section, InputArea, Logo } from './styles'

/**
 * @typedef {object} IFormWrapper
 * @property {{ password: string, confirmPassword: string }} values
 * @property {{ password: string, confirmPassword: string }} errors
 * @property {{ password: boolean, confirmPassword: boolean }} touched
 * @property {function} handleChange
 * @property {function} handleSubmit
 * @property {function} handleBlur
 */

/** @type {React.FC<IFormWrapper>} */
const FormWrapper = ({ values, errors, handleChange, touched, handleSubmit, handleBlur }) => {
  return (
    <>
      <Section>
        <LoginAreaWrapper>
          <Logo>
            <LogoProvi />
          </Logo>

          <ContainerText>
            <Title>Redefina a sua senha</Title>
          </ContainerText>

          <Form onSubmit={handleSubmit}>
            <InputArea>
              <Label isDark text="Digite sua nova senha*" />
              <Input
                placeholder="Digite sua nova senha*"
                id="password"
                type="password"
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: 'white' }} />
                    </InputAdornment>
                  ),
                  style: { color: 'white' }
                }}
              />
              {errors.password && touched.password && <TextErrorInput>{errors.password}</TextErrorInput>}
            </InputArea>
            <InputArea>
              <Label isDark text="Digite sua nova senha*" />
              <Input
                placeholder="Digite sua nova senha*"
                id="confirmed-password"
                type="password"
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: 'white' }} />
                    </InputAdornment>
                  ),
                  style: { color: 'white' }
                }}
              />
              {errors.password && touched.password && <TextErrorInput>{errors.password}</TextErrorInput>}
            </InputArea>

            <Button
              backgroundDisabled={theme.colors.blue800}
              text="Avançar"
              disabled={!(values.confirmPassword && values.password)}
              type="submit"
              marginHorizontal={0}
              marginVertical={15}
              onClick={handleSubmit}
            />
          </Form>
        </LoginAreaWrapper>
      </Section>
    </>
  )
}

export const FormFormik = withFormik({
  validationSchema: () => {
    const schema = {
      confirmPassword: Yup.string().required('Preencha o campo Senha'),
      password: Yup.string().required('Preencha o campo Senha')
    }
    return Yup.object().shape(schema)
  },

  handleSubmit: async (values, { props }) => {
    props.setIsLoading(true)

    try {
      const { confirmPassword, password } = values
      if (confirmPassword !== password) {
        showToast('Precisa ser a mesma senha nos dois campos')
      } else {
        const email = await asyncLocalStorage.getItem('email')
        await updatePassword({ email, password, confirmPassword })
        history.push('/contratos')
      }
    } catch (err) {
      showToast('Erro ao alterara senha.')
      console.log('Erro ao alterara senha.')
    } finally {
      props.setIsLoading(false)
    }
  }
})(FormWrapper)
