import styled from 'styled-components'

import { theme } from '@provi/provi-components'

export const DownloadWrapper = styled.div`
  transform: translateY(5px);
`

export const Content = styled.div`
  margin-top: 81px;
  height: calc(100vh - 81px);
  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 265px;
    height: calc(100% - 265px);
  }
`

export const ContainerTable = styled.div`
  height: 100%;
  width: 100vw;
`
