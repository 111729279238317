import styled from 'styled-components'
import { TableBody } from '@material-ui/core'

export const TableBodyAdapter = styled(TableBody)`
  > tr:nth-child(even) {
    background: #f9f9f9;
  }
`
export const DateWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DropItens = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;

  max-height: 400px;

  width: fit-content;
  min-width: 300px;

  height: 450px;
  background-color: #fff;
  overflow-y: scroll;
`

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const ButtonFilter = styled.div`
  height: 30px;
  width: 100px;
  background-color: #de0c4b;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 20px;
  font-family: CenturyGothicBold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
`

export const ContainerLoadMore = styled.div`
  display: flex;
  justify-content: center;
`
